import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class TeamsRepository extends BaseRepository {
    baseUrl = 'management/concessions/teams';

    /**
     * Performs a search given some criteria, returns raw
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchMap(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search-map`, criteria);
    }
}
